import { Form, Input, Menu } from 'antd';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useHistory, useRouteMatch } from 'react-router-dom';
import { Modal } from '../components/modals/antd-modals';
import { BasicFormWrapper } from '../container/styled';
import CompaniesService from '../service/companies';
import LoginService from '../service/login';
import { SUPER_ADMIN_ROLE } from '../utility/constants';
import { openNotificationError, openNotificationSuccess } from '../utility/helpers';

const loginservice = new LoginService();
const companiesservice = new CompaniesService();
const { SubMenu } = Menu;

const MenuItems = ({ darkMode, toggleCollapsed, topMenu, events }) => {
  const [form] = Form.useForm();
  const states = useSelector(state => state?.userByCompany);

  const jwt = require('jsonwebtoken');
  const [state, setState] = useState({
    companies: [],
    visibleModal: false,
    role: '',
  });

  const { path } = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    return history.listen(location => {
      console.log(`You changed the page to: ${location.pathname}`);
      fetchCompanies();
    });
  }, [history]);

  useEffect(() => {
    if (state.role === SUPER_ADMIN_ROLE) {
      fetchCompanies();
    }
  }, [state.role]);

  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');

  const { onRtlChange, onLtrChange, modeChangeDark, modeChangeLight, modeChangeTopNav, modeChangeSideNav } = events;
  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const fetchCompanies = () => {
    companiesservice
      .getCompanies()
      .then(reponse => {
        const companies = reponse.data.data;
        setState(st => ({
          ...st,
          companies: companies.length ? companies : [companies],
        }));
      })
      .catch(error => {
        console.log('error', error.response);
        openNotificationError('error', 'Unable to fetch companies for menu');
      });
  };
  const onOpenChange = keys => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = item => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const addCompaniesModal = () => {
    setState(st => ({
      ...st,
      visibleModal: true,
    }));
  };
  const handleCompanyNameChange = e => {
    e.persist();
    setState(st => ({
      ...st,
      company: e.target.value,
    }));
  };
  const handleCompanyAddressChange = e => {
    e.persist();
    setState(st => ({
      ...st,
      address: e.target.value,
    }));
  };

  const handleSaveCompany = () => {
    const slug =
      state.company &&
      state.company
        .toLowerCase()
        .split(' ')
        .join('-');
    const name = state.company;
    const { address } = state;
    const values = { slug, name, address };

    companiesservice
      .createCompany(values)
      .then(reponse => {
        openNotificationSuccess('success', 'The company has been added');
        fetchCompanies();
        form.resetFields();
        setState(st => ({
          ...st,
          visibleModal: false,
        }));
      })
      .catch(error => {
        console.log('error', error.response);
        openNotificationError('error', 'Unable to add company');
      });
  };

  useEffect(() => {
    const token = jwt.decode(loginservice.getToken());
    let role;
    if (token && token.role_id === 1) {
      role = 'superAdmin';
    } else if (token && token.role_id === 2) {
      role = 'admin';
    } else {
      role = 'user';
    }

    setState(st => ({
      ...st,
      role,
    }));
  }, []);

  return (
    <>
      <Modal
        type="success"
        title="Add New Company"
        visible={state.visibleModal}
        onOk={handleSaveCompany}
        onCancel={() =>
          setState(st => ({
            ...st,
            visibleModal: false,
          }))
        }
      >
        <BasicFormWrapper>
          <Form form={form} name="addCompany" className="custom-form">
            <Form.Item name="company" title="Name">
              <Input
                placeholder="Enter company name"
                name="company"
                value={state.company}
                onChange={e => handleCompanyNameChange(e)}
              />
            </Form.Item>
            <Form.Item name="address" title="Address">
              <Input
                placeholder="Enter company adresse"
                name="address"
                value={state.address}
                onChange={e => handleCompanyAddressChange(e)}
              />
            </Form.Item>
          </Form>
        </BasicFormWrapper>
      </Modal>
      <Menu
        onOpenChange={onOpenChange}
        onClick={onClick}
        mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
        theme={darkMode && 'dark'}
        // // eslint-disable-next-line no-nested-ternary
        defaultSelectedKeys={
          !topMenu
            ? [
                `${
                  mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
                }`,
              ]
            : []
        }
        defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
        overflowedIndicator={<FeatherIcon icon="more-vertical" />}
        openKeys={openKeys}
      >
        <Menu.Item
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}/`}>
                <FeatherIcon icon="layout" />
              </NavLink>
            )
          }
          key="dashboard"
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/`}>
            Dashboard
          </NavLink>
        </Menu.Item>
        {state.role === SUPER_ADMIN_ROLE && (
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/cultivar`}>
                  <FeatherIcon icon="slack" />
                </NavLink>
              )
            }
            key="cultivar"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/cultivar`}>
              Cultivar
            </NavLink>
          </Menu.Item>
        )}
        {state.role === SUPER_ADMIN_ROLE && (
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/treatment`}>
                  <FeatherIcon icon="shield" />
                </NavLink>
              )
            }
            key="treatment"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/treatment`}>
              Treatment
            </NavLink>
          </Menu.Item>
        )}
        {state.role === SUPER_ADMIN_ROLE ? (
          <SubMenu key="company" icon={!topMenu && <FeatherIcon icon="briefcase" />} title="Companies">
            {state.companies?.map(item => {
              return (
                <Menu.Item key={item.slug}>
                  <NavLink onClick={toggleCollapsed} to={`${path}/company/${item.slug}`}>
                    {item.name}
                  </NavLink>
                </Menu.Item>
              );
            })}
            {/* <Menu.Item key="add" className="plus-icon"> */}
            <span onClick={addCompaniesModal} className="plus-icon">
              <img src={require(`../static/img/custom/plus.png`)} alt="menu" />
            </span>
          </SubMenu>
        ) : (
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/company/${states?.user.slug}`}>
                  <FeatherIcon icon="briefcase" />
                </NavLink>
              )
            }
            key="company"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/company/${states?.user.slug}`}>
              Company
            </NavLink>
          </Menu.Item>
        )}
        {state.role === SUPER_ADMIN_ROLE && (
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/registration-requests`}>
                  <FeatherIcon icon="mail" />
                </NavLink>
              )
            }
            key="reg-requests"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/registration-requests`}>
              Registration requests
            </NavLink>
          </Menu.Item>
        )}
        {state.role === SUPER_ADMIN_ROLE && (
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/license`}>
                  <FeatherIcon icon="award" />
                </NavLink>
              )
            }
            key="license"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/license`}>
              License
            </NavLink>
          </Menu.Item>
        )}
        {state.role === SUPER_ADMIN_ROLE && (
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/mqtt`}>
                  <FeatherIcon icon="cloud-lightning" />
                </NavLink>
              )
            }
            key="Mqtt"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/mqtt`}>
              Mqtt
            </NavLink>
          </Menu.Item>
        )}
        {state.role === SUPER_ADMIN_ROLE && (
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/heartbeat`}>
                  <FeatherIcon icon="activity" />
                </NavLink>
              )
            }
            key="heartbeat"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/heartbeat`}>
              Heartbeat
            </NavLink>
          </Menu.Item>
        )}
      </Menu>
    </>
  );
};

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
  events: propTypes.object,
};

export default MenuItems;
