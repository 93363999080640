import axios from 'axios';
import LoginService from './login';
import { API_URL } from '../utility/constants';

const loginservice = new LoginService();

export default class usersService {
  async getUsers(slug) {
    const token = loginservice.getToken();
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const data = {
      slug,
    };
    const option = {
      url: `${API_URL}/company_users/get_company_user`,
    };
    return axios.post(option.url, data, config);
  }

  async getMqttUsers(companyId) {
    const token = loginservice.getToken();

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const data = {
      company_id: companyId,
    };
    const option = {
      url: `${API_URL}/users/get_mqtt_user`,
    };
    return axios.post(option.url, data, config);
  }

  async updateUser(values) {
    const token = loginservice.getToken();
    return axios.put(
      `${API_URL}/users/update_user`,
      {
        user_id: values.user_id,
        lastname: values.lastname,
        firstname: values.firstname,
        email: values.email,
        password: values.password,
        newPassword: values.password,
        role_id: values.role_id,
        slug: values.slug,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  }

  async addUser(values) {
    const token = loginservice.getToken();

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const option = {
      url: `${API_URL}/users/sign_up`,
    };
    const data = {
      lastname: values.lastname,
      firstname: values.firstname,
      email: values.email,
      password: values.password,
      role_id: values.role_id,
      slug: values.slug,
    };
    return axios.post(option.url, data, config);
  }

  async addMqttUser(values) {
    const token = loginservice.getToken();

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const option = {
      url: `${API_URL}/users/sign_up/mqtt`,
    };
    const data = {
      company_id: values,
    };
    return axios.post(option.url, data, config);
  }

  async deleteUser(values) {
    const token = loginservice.getToken();
    return axios.put(
      `${API_URL}/users/delete_user`,
      {
        userId: values.id,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  }

  async deleteMqttUser(values) {
    const token = loginservice.getToken();

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const option = {
      url: `${API_URL}/users/delete_mqtt_user`,
    };
    const data = {
      user_id: values,
    };
    return axios.put(option.url, data, config);
  }

  async updateRight(data) {
    const token = loginservice.getToken();

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const option = {
      url: `${API_URL}/company_users/update_user_site_right`,
    };
    return axios.post(option.url, data, config);
  }

  getUserCompany(id) {
    const token = loginservice.getToken();
    const data = {
      user_id: id,
    };

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const option = {
      url: `${API_URL}/users/get_user_company`,
    };

    return axios.post(option.url, data, config);
  }

  updateUserDetails(data) {
    const token = loginservice.getToken();

    const payload = {
      user_id: data.getFieldValue('id'),
      firstname: data.getFieldValue('firstname'),
      lastname: data.getFieldValue('lastname'),
      email: data.getFieldValue('email'),
      role_id: data.getFieldValue('role_id'),
      password: data.getFieldValue('password'),
    };

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const option = {
      url: `${API_URL}/users/update_user`,
    };

    return axios.put(option.url, payload, config);
  }

  getUserDetail() {
    const token = loginservice.getToken();

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const option = {
      url: `${API_URL}/users/get_user_detail`,
    };

    return axios.get(option.url, config);
  }

  userOldPassword(data) {
    const token = loginservice.getToken();
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const option = {
      url: `${API_URL}/users/validate_old_password`,
    };

    return axios.post(option.url, data, config);
  }

  sendAccess(data) {
    const token = loginservice.getToken();
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const option = {
      url: `${API_URL}/users/send_access`,
    };

    return axios.post(option.url, data, config);
  }

  getRoles() {
    const token = loginservice.getToken();

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    return axios.get(`${API_URL}/users/roles`, config);
  }

  isAdmin() {
    const { payload } = loginservice.getDecodedToken();
    return payload.role_id === 2 || payload.role_id === 1;
  }

  isSuperAdmin() {
    const { payload } = loginservice.getDecodedToken();
    return payload.role_id === 1;
  }
}
