export const API_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:9001'
    : 'https://optiflux-production-64xtm.ondigitalocean.app/backend';
export const TOKEN = 'op-token';
export const MAX_AGE = 60 * 60 * 2; // 2 hours
export const ADMIN_ROLE = 'admin';
export const SUPER_ADMIN_ROLE = 'superAdmin';
export const NOTIFICATION_ADDRESS_TYPES = {
  EMAIL: 'EMAIL',
  PHONE: 'SMS',
};
