/* eslint-disable react/jsx-no-bind */
import { useQuery } from '@tanstack/react-query';
import { Button, Select } from 'antd';
import axios from 'axios';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { login } from '../redux/authentication/actionCreator';
import { selectCompanyHandler } from '../redux/userAccess/actions';
import { setUserCompanyData } from '../redux/userCompany/action';
import LoginService from '../service/login';
import UserService from '../service/users';
import { API_URL } from '../utility/constants';
import { setItem } from '../utility/localStorageControl';

const loginService = new LoginService();
const userService = new UserService();

async function getUsers() {
  const res = await axios.get(`${API_URL}/users`, {
    headers: { Authorization: `Bearer ${loginService.getToken()}` },
  });
  return res.data.data;
}

async function impersonate(user) {
  const res = await axios.post(
    `${API_URL}/users/impersonate`,
    {
      user,
    },
    {
      headers: { Authorization: `Bearer ${loginService.getToken()}` },
    },
  );
  return res.data?.token;
}

async function stopImpersonating() {
  const res = await axios.post(
    `${API_URL}/users/stop-impersonation`,
    {},
    { headers: { Authorization: `Bearer ${loginService.getToken()}` } },
  );
  return res.data?.token;
}

export default function UserSelect() {
  const { data: users, isLoading } = useQuery({
    queryKey: ['users'],
    queryFn: getUsers,
  });
  const dispatch = useDispatch();

  const isSuperAdmin = useMemo(() => {
    const decoded = loginService.getDecodedToken();
    if (!decoded) {
      return false;
    }
    return decoded.payload?.role_id === 1;
  }, []);

  const isImpersonating = useMemo(() => {
    const decoded = loginService.getDecodedToken();
    if (!decoded) {
      return false;
    }
    return !!decoded.payload?.impersonatedBy;
  }, []);

  async function onUserSelect(user) {
    const newToken = await impersonate(user);
    loginService.setToken(newToken);
    dispatch(login());
    const { payload: impersonatedUser } = loginService.getDecodedToken();
    if (impersonatedUser?.role_id >= 2) {
      try {
        const res = await userService.getUserCompany(impersonatedUser?.id);
        dispatch(selectCompanyHandler(res?.data?.data?.id));
        dispatch(setUserCompanyData(res?.data?.data));
        setItem('user', res?.data?.data);
        window.location.reload();
      } catch (err) {
        console.log('Some error', err);
      }
    } else {
      dispatch(setUserCompanyData(''));
      window.location.reload();
    }
  }

  async function onStopImpersonating() {
    const newToken = await stopImpersonating();
    loginService.setToken(newToken);
    dispatch(login());
    dispatch(setUserCompanyData(''));
    window.location.reload();
  }

  const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  if (isLoading || !users) {
    return null;
  }

  if (isImpersonating) {
    return <Button onClick={onStopImpersonating}>Stop impersonation</Button>;
  }

  if (!isSuperAdmin) {
    return null;
  }

  return (
    <Select
      style={{
        width: '15rem',
      }}
      onChange={onUserSelect}
      placeholder="Select a user to impersonate"
      showSearch
      filterOption={filterOption}
      optionFilterProp="children"
      options={users.map(u => ({ value: u.id, label: u.email }))}
    />
  );
}
