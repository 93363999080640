import * as jwt from 'jsonwebtoken';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { API_URL, MAX_AGE, TOKEN } from '../utility/constants';
import LoginService from './login';
const loginservice = new LoginService();

export default class Heartbeatservice {
  async listing() {
    const token = loginservice.getToken();
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const option = {
      url: `${API_URL}/heartbeat`,
    };

    return axios.post(option.url, "",config);
  }
  async settings() {
   
    const option = {
      url: `${API_URL}/heartbeat/settings`,
    };

    return axios.get(option.url);
  }
  async updateSettings(values) {
    const token = loginservice.getToken();

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const option = {
      url: `${API_URL}/heartbeat/update_settings`,
    };
    const data = {
      measurements: values.measurementsInterval,
      sensors: values.sensorsInterval,
      emails: values.emails,
      phones: values.phones,
    };
    return axios.put(option.url, data, config);
  }
  async delete(values) {
    const token = loginservice.getToken();

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const option = {
      url: `${API_URL}/heartbeat/delete`,
    };
    const data = {
      id: values.id,
    };
    return axios.put(option.url, data, config);
  }
}
