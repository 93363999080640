import React from 'react';
import { Row, Col } from 'antd';
import { Aside, Content, LoginWrapper } from './overview/style';
import Heading from '../../../components/heading/heading';

const AuthLayout = WraperContent => {
  return () => {
    return (
      <LoginWrapper>
        <Row>
          <Col xxl={12} xl={13} lg={12} md={8} xs={24} className="banner-section">
            {/* <Aside> */}
            {/* <img src={require('../../../static/img/auth/topShape.png')} alt="" className="topShape" />
              <img src={require('../../../static/img/auth/bottomShape.png')} alt="" className="bottomShape" /> */}
            {/* <Content> */}
            {/* <img style={{ width: '150px' }} src={require('../../../static/img/Logo_Dark.svg')} alt="" />
                <br />
                <br />
              <Heading as="h1">Optiflex Admin</Heading> */}
            <img className="small-logo" src={require('../../../static/img/auth/logo-small.svg')} alt="" />

            <img
              className="auth-content-figure"
              style={{ height: '100vh' }}
              src={require('../../../static/img/auth/banner-new.png')}
              alt=""
            />
            {/* </Content> */}
            {/* </Aside> */}
          </Col>

          <Col xxl={12} xl={11} lg={12} md={16} xs={24} className="login-form-col">
            <WraperContent />
          </Col>
        </Row>
      </LoginWrapper>
    );
  };
};

export default AuthLayout;
