import React from 'react';
import { arrayOf, bool, func, node, number, object, oneOfType, string } from 'prop-types';
import { ModalStyled } from './styled';
import { Button } from '../buttons/buttons';

const Modal = ({
  onCancel,
  className,
  canSave = true,
  onOk,
  visible,
  title,
  type,
  color,
  footer,
  width,
  children,
  zIndex,
}) => {
  return (
    <ModalStyled
      title={title}
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      type={color ? type : false}
      width={width}
      className={className}
      zIndex={zIndex}
      canSave={canSave}
      footer={
        footer || footer === null
          ? footer
          : [
              <Button type={type} outlined key="back" onClick={onCancel}>
                Cancel
              </Button>,
              <Button type={type} key="submit" onClick={onOk} disabled={!canSave}>
                Save
              </Button>,
            ]
      }
    >
      {children}
    </ModalStyled>
  );
};

Modal.defaultProps = {
  width: 620,
  className: 'atbd-modal',
};

Modal.propTypes = {
  onCancel: func,
  canSave: bool,
  onOk: func,
  visible: bool,
  title: string,
  zIndex: number,
  className: string,
  type: string,
  footer: arrayOf(object),
  width: number,
  color: oneOfType([bool, string]),
  children: oneOfType([object, string, node]),
};

const alertModal = ModalStyled;
export { Modal, alertModal };
