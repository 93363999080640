import { combineReducers } from 'redux';
import authReducer from './authentication/reducers';
import userByCompany from './userCompany/reducers';
import accessReducer from './userAccess/reducers';
import PrivacyModal from './privacyPolicy/reducers';
import selectOptionsReducer from './selectOptions/reducers';

const rootReducers = combineReducers({
  auth: authReducer,
  userByCompany,
  accessReducer,
  PrivacyModal,
  selectOptions: selectOptionsReducer,
});

export default rootReducers;
