import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import FeatherIcon from 'feather-icons-react';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import { logOut } from '../../../redux/authentication/actionCreator';
import { showPrivacyModal } from '../../../redux/privacyPolicy/actions';
import { selectCompanyHandler } from '../../../redux/userAccess/actions';
import { setUserCompanyData } from '../../../redux/userCompany/action';
import LoginService from '../../../service/login';
import UserService from '../../../service/users';
import { API_URL } from '../../../utility/constants';
import { setItem } from '../../../utility/localStorageControl';
import Heading from '../../heading/heading';
import { Popover } from '../../popup/popup';
import { InfoWraper, UserDropDwon } from './auth-info-style';
import { Select } from 'antd';

const userService = new UserService();
const loginService = new LoginService();

const AuthInfo = () => {
  const { path } = useRouteMatch();
  const [email, setEmail] = useState('User email');
  const dispatch = useDispatch();

  useEffect(() => {
    const decodedToken = loginService.getDecodedToken();
    const token = decodedToken?.payload;
    if (token?.role_id === 2) {
      userService
        .getUserCompany(token?.id)
        .then(response => {
          dispatch(selectCompanyHandler(response?.data?.data?.id));
          dispatch(setUserCompanyData(response?.data?.data));
          setItem('user', response?.data?.data);
        })
        .catch();
    } else {
      dispatch(setUserCompanyData(''));
    }
    if (token && token.email) {
      setEmail(token.email);
    } else {
      dispatch(logOut());
      loginService.Logout();
    }
  }, []);

  const SignOut = e => {
    e.preventDefault();
    dispatch(logOut());
    loginService.Logout();
  };

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          <figcaption>
            <Heading as="h5">{email}</Heading>
          </figcaption>
        </figure>
        <ul className="user-dropdwon__links">
          <li>
            <Link to={`${path}/profile`}>
              <FeatherIcon icon="user" /> Profile
            </Link>
          </li>
          <li>
            <Link
              to="#"
              onClick={() => {
                dispatch(showPrivacyModal(true));
              }}
            >
              <FeatherIcon icon="eye" /> Privacy policy
            </Link>
          </li>
        </ul>
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <FeatherIcon icon="log-out" /> Sign Out
        </Link>
      </div>
    </UserDropDwon>
  );

  return (
    <InfoWraper>
      <div className="nav-author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="head-example">
            <FeatherIcon icon="user" />
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
};

export default AuthInfo;
