import * as jwt from 'jsonwebtoken';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { API_URL, MAX_AGE, TOKEN } from '../utility/constants';

const cookies = new Cookies();

// axios.interceptors.response.use(function (response) {
//   return response;
// }, function (error) {
//   if (401 === error.response.status) {
//       return Promise.reject(error);

//   } else {
//       return Promise.reject(error);
//   }
// });
// const defaultCookieOptions = {
//   path: '/',
//   sameSite: 'none',
//   secure: true,
// };

// const saveToken = token => {
//   const cookieOptions = {
//     ...defaultCookieOptions,
//     maxAge: MAX_AGE,
//   };
//   cookies.set(TOKEN, token, cookieOptions);
//   cookies.set('tokenExpiry', new Date().getTime() + MAX_AGE * 1000, cookieOptions);
// };

export default class LoginService {
  setToken(token) {
    cookies.set(TOKEN, token, {
      maxAge: MAX_AGE,
      path: '/',
      sameSite: 'none',
      secure: true,
    });
    localStorage.setItem(TOKEN, token);
  }

  //    doLogin(token, username, password) {
  //     const decodedToken = jwt.decode(token, { complete: true });
  //     const flag = decodedToken.payload && decodedToken.payload.userTexting ? false : true;
  //     cookies.set('restricted', flag, {
  //       path: '/',
  //       sameSite: 'none',
  //       secure: true,
  //     });

  //     localStorage.setItem('restricted', flag);
  //     saveToken(token);
  //     localStorage.setItem(TOKEN, token);
  //     cookies.set('username', username, {
  //       path: '/',
  //       sameSite: 'none',
  //       secure: true,
  //     });
  //     cookies.set('password', password, {
  //       path: '/',
  //       sameSite: 'none',
  //       secure: true,
  //     });
  //   }

  //    updateToken(token, username, password) {
  //     this.doLogin(token, username, password);
  //   }

  getToken() {
    let token;
    try {
      token = cookies.get(TOKEN, {
        path: '/',
        sameSite: 'none',
        secure: true,
      });
      if (!token) {
        token = localStorage.getItem(TOKEN);
      }
    } catch (e) {
      console.log(e);
    }
    return token;
  }

  getDecodedToken() {
    const token = this.getToken();
    const decodedToken = jwt.decode(token, { complete: true });
    return decodedToken;
  }

  getName() {
    const decodedToken = this.getDecodedToken();
    return decodedToken.payload.name;
  }

  isSuperadmin() {
    const decodedToken = this.getDecodedToken();
    return decodedToken.payload.role_id === 1;
  }

  isLoggedin() {
    if (this.getToken()) {
      return true;
    }
    return false;
  }

  deleteCookie() {
    cookies.remove(TOKEN, {
      path: '/',
      sameSite: 'none',
      secure: true,
    });
    // cookies.remove('restricted', { path: '/', sameSite: 'none', secure: true });
    // cookies.remove('logoutFlag', { path: '/', sameSite: 'none', secure: true });
    // cookies.remove('urlId', { path: '/', sameSite: 'none', secure: true });
    // cookies.remove('permissions', {
    //   path: '/',
    //   sameSite: 'none',
    //   secure: true,
    // });
    localStorage.clear();
  }

  Logout() {
    this.deleteCookie();
  }

  async loginUser(email, password) {
    const credentials = {
      email,
      password,
    };
    const option = {
      url: `${API_URL}/users/login`,
      data: credentials,
    };

    return axios.post(`${API_URL}/users/login`, credentials);

    //  axios.post(option.url,option.data).then(function(reponse){
    //    console.log("data",reponse)
    //   return reponse;
    //  })
    //  .catch(function(error){
    //   console.log("data error",error.response)

    //   return error;

    //  })
  }

  //    forgetPassword(userIdentification) {
  //     const option = {
  //       url: `${API_URL}/forgotPassword?userIdentification=${userIdentification}`,
  //     };
  //     return axios.get(option);
  //   }
}
