import { Alert, Button, Col, Layout, Row, Space } from 'antd';
import propTypes from 'prop-types';
import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import MenueItems from './MenueItems';
import { Div } from './style';
import LoginService from '../service/login';
import AuthInfo from '../components/utilities/auth-info/info';
import { changeLayoutMode, changeMenuMode, changeRtlMode } from '../redux/themeLayout/actionCreator';
import HeartbeatService from '../service/heartbeat';
import NotificationsService from '../service/notifications';
import { openNotificationError } from '../utility/helpers';
import { Modal } from '../components/modals/antd-modals';
import UserSelect from '../components/UserSelect';

const heartbeatservice = new HeartbeatService();
const notificationsservice = new NotificationsService();
const { darkTheme } = require('../config/theme/themeVariables');

const loginservice = new LoginService();
const token = loginservice.getDecodedToken();
const { Header, Footer, Sider, Content } = Layout;

const ThemeLayout = WrappedComponent => {
  class LayoutComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        collapsed: false,
        hide: true,
        searchHide: true,
        customizerAction: false,
        activeSearch: false,
        heartbeatCount: 0,
        isModalOpen: false,
        alert_details: {},
      };
      this.updateDimensions = this.updateDimensions.bind(this);
      this.intervalId = '';
    }

    componentDidMount() {
      window.addEventListener('resize', this.updateDimensions);
      this.updateDimensions();
      const { payload } = loginservice.getDecodedToken();
      if (payload.role_id === 1) {
        this.fetchHeartbeat();
        this.fetchSimulationNotifications();
        this.intervalId = setInterval(() => {
          this.fetchHeartbeat();
          this.fetchSimulationNotifications();
        }, 30000);
      }
    }

    componentDidUpdate() {
      // this.fetchHeartbeat();
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimensions);
      clearInterval(this.intervalId);
    }

    fetchHeartbeat = () => {
      heartbeatservice
        .listing()
        .then(reponse => {
          const heartbeatCount = reponse.data.count;
          this.setState(st => ({
            ...st,
            heartbeatCount,
          }));
        })
        .catch(error => {
          console.log('error', error);
          openNotificationError('error', 'Unable to fetch heartbeat');
        });
    };

    fetchSimulationNotifications = () => {
      notificationsservice.getSimulationNotifications().then(response => {
        const notificationsCount = response.data.count;
        const alerts = response.data.data;
        this.setState(st => ({
          ...st,
          notificationsCount,
          alerts,
        }));
      });
    };

    updateSimulationNotifications = data => {
      notificationsservice.updateSimulationNotifications(data).then(() => {
        this.fetchSimulationNotifications();
      });
    };

    updateDimensions() {
      this.setState({
        collapsed: window.innerWidth <= 1200 && true,
      });
    }

    render() {
      const { collapsed, hide, searchHide, activeSearch, isModalOpen, alert_details } = this.state;
      const { ChangeLayoutMode, rtl, topMenu, changeRtl, changeLayout, changeMenuMode } = this.props;

      const left = !rtl ? 'left' : 'right';
      const darkMode = true;
      const toggleCollapsed = () => {
        this.setState({
          collapsed: !collapsed,
        });
      };

      const toggleCollapsedMobile = () => {
        if (window.innerWidth <= 990) {
          this.setState({
            collapsed: !collapsed,
          });
        }
      };

      const onShowHide = () => {
        this.setState({
          hide: !hide,
          searchHide: true,
        });
      };

      const toggleSearch = () => {
        this.setState({
          activeSearch: !activeSearch,
        });
      };

      const handleSearchHide = e => {
        e.preventDefault();
        this.setState({
          searchHide: !searchHide,
          hide: true,
        });
      };

      const footerStyle = {
        padding: '20px 30px 18px',
        color: 'rgba(0, 0, 0, 0.65)',
        fontSize: '14px',
        background: 'rgba(255, 255, 255, .90)',
        width: '100%',
        boxShadow: '0 -5px 10px rgba(146,153,184, 0.05)',
      };

      const SideBarStyle = {
        margin: '63px 0 0 0',
        padding: '15px 15px 55px 15px',
        overflowY: 'auto',
        height: '100vh',
        position: 'fixed',
        [left]: 0,
        zIndex: 998,
      };

      const renderView = ({ style, ...props }) => {
        const customStyle = {
          marginRight: 'auto',
          [rtl ? 'marginLeft' : 'marginRight']: '-17px',
        };
        return <div {...props} style={{ ...style, ...customStyle }} />;
      };

      const renderThumbVertical = ({ style, ...props }) => {
        const { ChangeLayoutMode } = this.props;
        const thumbStyle = {
          borderRadius: 6,
          backgroundColor: ChangeLayoutMode ? '#ffffff16' : '#F1F2F6',
          [left]: '2px',
        };
        return <div style={{ ...style, ...thumbStyle }} props={props} />;
      };

      const renderTrackVertical = () => {
        const thumbStyle = {
          position: 'absolute',
          width: '6px',
          transition: 'opacity 200ms ease 0s',
          opacity: 0,
          [rtl ? 'left' : 'right']: '2px',
          bottom: '2px',
          top: '2px',
          borderRadius: '3px',
        };
        return <div style={thumbStyle} />;
      };

      const renderThumbHorizontal = ({ style, ...props }) => {
        const { ChangeLayoutMode } = this.props;
        const thumbStyle = {
          borderRadius: 6,
          backgroundColor: ChangeLayoutMode ? '#ffffff16' : '#F1F2F6',
        };
        return <div style={{ ...style, ...thumbStyle }} props={props} />;
      };

      const onRtlChange = () => {
        const html = document.querySelector('html');
        html.setAttribute('dir', 'rtl');
        changeRtl(true);
      };

      const onLtrChange = () => {
        const html = document.querySelector('html');
        html.setAttribute('dir', 'ltr');
        changeRtl(false);
      };

      const modeChangeDark = () => {
        changeLayout(true);
      };

      const modeChangeLight = () => {
        changeLayout(false);
      };

      const modeChangeTopNav = () => {
        changeMenuMode(true);
      };

      const modeChangeSideNav = () => {
        changeMenuMode(false);
      };

      const onEventChange = {
        onRtlChange,
        onLtrChange,
        modeChangeDark,
        modeChangeLight,
        modeChangeTopNav,
        modeChangeSideNav,
      };
      return (
        <>
          <Div darkMode={darkMode}>
            <Layout className="layout">
              <Header
                style={{
                  position: 'fixed',
                  width: '100%',
                  top: 0,
                  [!rtl ? 'left' : 'right']: 0,
                }}
              >
                <Row>
                  <Modal
                    type="success"
                    title={isModalOpen && alert_details?.error}
                    visible={isModalOpen}
                    // footer={}
                    className="custom-modal"
                    zIndex={1001}
                    onOk={() => {
                      this.updateSimulationNotifications(alert_details.id);

                      this.setState({
                        isModalOpen: !isModalOpen,
                      });
                    }}
                    onCancel={() =>
                      this.setState({
                        isModalOpen: !isModalOpen,
                      })
                    }
                  >
                    <p>{alert_details?.description}</p>
                  </Modal>
                  <Col lg={!topMenu ? 4 : 3} sm={6} xs={12} className="align-center-v navbar-brand">
                    {!topMenu || window.innerWidth <= 991 ? (
                      <Button type="link" onClick={toggleCollapsed}>
                        <img src={require(`../static/img/icon/${collapsed ? 'right.svg' : 'left.svg'}`)} alt="menu" />
                      </Button>
                    ) : null}
                    <Link
                      className={topMenu && window.innerWidth > 991 ? 'striking-logo top-menu' : 'striking-logo'}
                      to="/admin"
                    >
                      <img src={require(`../static/img/custom/optiflux-logo.svg`)} alt="" />
                    </Link>
                  </Col>

                  {/* <Col lg={!topMenu ? 14 : 15} md={8} sm={0} xs={0}>
                  {topMenu && window.innerWidth > 991 ? <TopMenu /> : <HeaderSearch rtl={rtl} darkMode={darkMode} />}
                </Col> */}

                  <Col offset={14} xs={0} md={4}>
                    <UserSelect />
                  </Col>

                  <Col xs={0} sm={0} md={2}>
                    {/* {topMenu && window.innerWidth > 991 ? (
                    <TopMenuSearch>
                      <div className="top-right-wrap d-flex">
                        <Link
                          className={`${activeSearch ? 'search-toggle active' : 'search-toggle'}`}
                          onClick={() => {
                            toggleSearch();
                          }}
                          to="#"
                        >
                          <FeatherIcon icon="search" />
                          <FeatherIcon icon="x" />
                        </Link>
                        <div className={`${activeSearch ? 'topMenu-search-form show' : 'topMenu-search-form'}`}>
                          <form action="">
                            <span className="search-icon">
                              <FeatherIcon icon="search" />
                            </span>
                            <input type="text" name="search" />
                          </form>
                        </div>
                        <AuthInfo />
                      </div>
                    </TopMenuSearch>
                  ) : ( */}

                    <AuthInfo />
                    {/* )} */}
                  </Col>

                  {/* <Col md={0} sm={18} xs={12}>
                  <>
                    <div className="mobile-action">
                      <Link className="btn-search" onClick={handleSearchHide} to="#">
                        {searchHide ? <FeatherIcon icon="search" /> : <FeatherIcon icon="x" />}
                      </Link>
                      <Link className="btn-auth" onClick={onShowHide} to="#">
                        <FeatherIcon icon="more-vertical" />
                      </Link>
                    </div>
                  </>
                </Col> */}

                  {this.state.heartbeatCount > 0 && token?.payload?.role_id == 1 && (
                    <Alert
                      description="HEARTBEAT - There is errors"
                      type="error"
                      showIcon
                      banner
                      action={
                        <Space align="baseline">
                          <Button
                            size="small"
                            danger
                            onClick={() => {
                              this.props.history.push('/admin/heartbeat');
                            }}
                          >
                            Go to Heartbeat
                          </Button>
                        </Space>
                      }
                      style={{
                        position: 'fixed',
                        zIndex: 999999999999,
                        width: '30%',
                        left: '50%',
                        transform: 'translate(-50%, 0)',
                        backgroundColor: 'rgba(245, 0, 0, 0.63)',
                        color: '#ffffff',
                      }}
                    />
                  )}
                </Row>

                {this.state.notificationsCount > 0 &&
                  token?.payload?.role_id == 1 &&
                  this.state.alerts.map(x => (
                    <Col style={{ marginBottom: '65px' }}>
                      <Alert
                        description="The script is not executed properly"
                        type="error"
                        showIcon
                        banner
                        action={
                          <Space align="baseline">
                            <Button
                              size="small"
                              danger
                              onClick={() => {
                                this.setState({
                                  isModalOpen: !isModalOpen,
                                  alert_details: x,
                                });
                              }}
                            >
                              See Details
                            </Button>
                          </Space>
                        }
                        style={{
                          position: 'fixed',
                          zIndex: 999999999999,
                          width: '30%',
                          left: '50%',
                          transform: 'translate(-50%, 0)',
                          backgroundColor: '#fae0df',
                          color: 'Black',
                        }}
                      />{' '}
                    </Col>
                  ))}
              </Header>
              {/* <div className="header-more">
              <Row>
                <Col md={0} sm={24} xs={24}>
                  <div className="small-screen-headerRight">
                    <SmallScreenSearch hide={searchHide} darkMode={darkMode}>
                      <HeaderSearch rtl={rtl} />
                    </SmallScreenSearch>
                    <SmallScreenAuthInfo hide={hide} darkMode={darkMode}>
                      <AuthInfo rtl={rtl} />
                    </SmallScreenAuthInfo>
                  </div>
                </Col>
              </Row>
            </div> */}
              <Layout>
                {!topMenu || window.innerWidth <= 991 ? (
                  <ThemeProvider theme={darkTheme}>
                    <Sider width={280} style={SideBarStyle} collapsed={collapsed} theme={!darkMode ? 'light' : 'dark'}>
                      <Scrollbars
                        className="custom-scrollbar"
                        autoHide
                        autoHideTimeout={500}
                        autoHideDuration={200}
                        renderThumbHorizontal={renderThumbHorizontal}
                        renderThumbVertical={renderThumbVertical}
                        renderView={renderView}
                        renderTrackVertical={renderTrackVertical}
                      >
                        <p className="sidebar-nav-title">MAIN MENU</p>
                        <MenueItems
                          topMenu={topMenu}
                          rtl={rtl}
                          toggleCollapsed={toggleCollapsedMobile}
                          darkMode={darkMode}
                          events={onEventChange}
                        />
                      </Scrollbars>
                    </Sider>
                  </ThemeProvider>
                ) : null}
                <Layout className="atbd-main-layout">
                  <Content>
                    <WrappedComponent {...this.props} />
                    <Footer className="admin-footer" style={footerStyle}>
                      <Row>
                        <Col md={12} xs={24}>
                          <span className="admin-footer__copyright">2021 © Optiflux</span>
                        </Col>
                        {/* <Col md={12} xs={24}>
                        <div className="admin-footer__links">
                          <NavLink to="#">About</NavLink>
                          <NavLink to="#">Team</NavLink>
                          <NavLink to="#">Contact</NavLink>
                        </div>
                      </Col> */}
                      </Row>
                    </Footer>
                  </Content>
                </Layout>
              </Layout>
            </Layout>
          </Div>
        </>
      );
    }
  }

  const mapStateToProps = state => {
    return {
      ChangeLayoutMode: false,
      rtl: false,
      topMenu: false,
    };
  };

  const mapStateToDispatch = dispatch => {
    return {
      changeRtl: rtl => dispatch(changeRtlMode(rtl)),
      changeLayout: show => dispatch(changeLayoutMode(show)),
      changeMenuMode: show => dispatch(changeMenuMode(show)),
    };
  };

  LayoutComponent.propTypes = {
    ChangeLayoutMode: propTypes.bool,
    rtl: propTypes.bool,
    topMenu: propTypes.bool,
    changeRtl: propTypes.func,
    changeLayout: propTypes.func,
    changeMenuMode: propTypes.func,
  };

  return connect(mapStateToProps, mapStateToDispatch)(LayoutComponent);
};
export default ThemeLayout;
