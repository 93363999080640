import axios from 'axios';
import LoginService from './login';
import { API_URL } from '../utility/constants';

const loginservice = new LoginService();

export default class CompaniesService {
  async createCompany(values) {
    const token = loginservice.getToken();

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const data = {
      name: values.name,
      address: values.address,
      slug: values.slug,
    };
    const option = {
      url: `${API_URL}/companies/create_company`,
      data,
    };

    return axios.post(option.url, option.data, config);
  }

  async updateCompanyPreferences(dateFormat, datetimeFormat, isMetric, slug) {
    const token = loginservice.getToken();
    return axios.post(
      `${API_URL}/companies/update_date_format`,
      {
        dateFormat,
        datetimeFormat,
        isMetric,
        slug,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  }

  async updateCompany(values) {
    const token = loginservice.getToken();

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const data = {
      name: values.name,
      id: values.id,
      slug: values.slug,
    };
    const option = {
      url: `${API_URL}/companies/update_company`,
      data,
    };

    return axios.put(option.url, option.data, config);
  }

  async deleteCompany(values) {
    const token = loginservice.getToken();

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const data = {
      company_id: values.id,
    };
    const option = {
      url: `${API_URL}/companies/delete_company`,
      data,
    };

    return axios.put(option.url, option.data, config);
  }

  async getCompany(userId) {
    const data = {
      user_id: userId,
    };
    const token = loginservice.getToken();

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const option = {
      url: `${API_URL}/companies/get_company_admin`,
      data,
    };

    return axios.post(option.url, option.data, config);
  }

  async getCompanyBySlug(slug) {
    const data = {
      slug,
    };
    const option = {
      url: `${API_URL}/companies/get_company_by_slug`,
      data,
    };
    const token = loginservice.getToken();

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    return axios.post(option.url, option.data, config);
  }

  async getCompanies() {
    const token = loginservice.getToken();

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const option = {
      url: `${API_URL}/companies/get_company`,
    };

    return axios.get(option.url, config);
  }

  addFruitType(companyId, fruitTypeId) {
    return axios.post(
      `${API_URL}/companies/add-fruit-type`,
      {
        companyId,
        fruitTypeId,
      },
      {
        headers: {
          Authorization: `Bearer ${loginservice.getToken()}`,
        },
      },
    );
  }

  removeFruitType(companyId, fruitTypeId) {
    return axios.post(
      `${API_URL}/companies/remove-fruit-type`,
      {
        companyId,
        fruitTypeId,
      },
      {
        headers: {
          Authorization: `Bearer ${loginservice.getToken()}`,
        },
      },
    );
  }

  getAccessKeys() {
    return axios.get(`${API_URL}/companies/access-keys`, {
      headers: {
        Authorization: `Bearer ${loginservice.getToken()}`,
      },
    });
  }

  async createAccessKey(accessKey) {
    const res = await axios.post(`${API_URL}/companies/create-key`, accessKey, {
      headers: { Authorization: `Bearer ${loginservice.getToken()}` },
    });
    return res.data.data;
  }

  removeAccessKey(accessKeyId) {
    return axios.post(
      `${API_URL}/companies/remove-key`,
      {
        accessKeyId,
      },
      {
        headers: { Authorization: `Bearer ${loginservice.getToken()}` },
      },
    );
  }

  getCompanyUsage(slug) {
    return axios.get(`${API_URL}/companies/usage/${slug}`, {
      headers: { Authorization: `Bearer ${loginservice.getToken()}` },
    });
  }
}
