import { Spin } from 'antd';
import React, { lazy, Suspense } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Dashboard from './dashboard';

import withAdminLayout from '../../layout/withAdminLayout';

const RegistrationRequests = lazy(() => import('../../container/registration-requests/index'));
const Calendars = lazy(() => import('../../container/Calendar'));
const Cultivar = lazy(() => import('../../container/cultivar'));
const Treatment = lazy(() => import('../../container/treatment'));
const Company = lazy(() => import('../../container/company'));
const PdfQrcodeRoom = lazy(() => import('../../container/company/pdfQrcodeRoom'));
const PdfQrcodeRoomContent = lazy(() => import('../../container/company/pdfQrcodeRoomContent'));
const PdfQrcodeSite = lazy(() => import('../../container/company/pdfQrcodeSite'));
const License = lazy(() => import('../../container/license'));
const Mqtt = lazy(() => import('../../container/mqtt'));
const Heartbeat = lazy(() => import('../../container/heartbeat'));
const ProfileDetails = lazy(() => import('../../container/profileDetails/index'));
const PrivacyPolicy = lazy(() => import('../../components/privacy-policy'));

const Admin = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route path={path} component={Dashboard} />
        <Route path={`${path}/registration-requests`} component={RegistrationRequests} />
        <Route path={`${path}/profile`} component={ProfileDetails} />
        <Route path={`${path}/calendar`} component={Calendars} />
        <Route path={`${path}/cultivar`} component={Cultivar} />
        <Route path={`${path}/treatment`} component={Treatment} />
        <Route path={`${path}/company/:slug`} component={Company} />
        <Route path={`${path}/company-name`} exact component={Company} />
        <Route path={`${path}/pdf-qrcode-room/:id`} component={PdfQrcodeRoom} />
        <Route path={`${path}/pdf-qrcode-room-content/:id`} component={PdfQrcodeRoomContent} />
        <Route path={`${path}/pdf-qrcode-site/:id`} component={PdfQrcodeSite} />

        <Route path={`${path}/license`} component={License} />
        <Route path={`${path}/mqtt`} component={Mqtt} />
        <Route path={`${path}/heartbeat`} component={Heartbeat} />

        <PrivacyPolicy />
      </Suspense>
    </Switch>
  );
};

export default withAdminLayout(Admin);
