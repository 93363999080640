import axios from 'axios';
import { logOut } from '../redux/authentication/actionCreator';
import store from '../redux/store';
import LoginService from '../service/login';

export function intercepters() {
  axios.interceptors.response.use(
    response => response,
    error => {
      if (!error.response || error.response.status === 401) {
        const loginService = new LoginService();
        store.dispatch(logOut());
        loginService.Logout();
        console.log('AUTH ERROR', error);
      }
      return Promise.reject(error);
    },
  );
}
