import { notification } from 'antd';
import FeatherIcon from 'feather-icons-react';
import FileSaver from 'file-saver';
import QRCode from 'qrcode.react';
import React from 'react';
import { ArrowDownOutlined, QrcodeOutlined } from '@ant-design/icons';
import { Button } from '../components/buttons/buttons';
import LoginService from '../service/login';
import { SUPER_ADMIN_ROLE } from './constants';

export const columns = showEditModal => {
  return [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (text, record) => {
        return (
          <div className="table-actions">
            <Button className="btn-icon" onClick={() => showEditModal(record)} type="info" to="#" shape="circle">
              <FeatherIcon icon="edit" size={16} />
            </Button>
            <Button className="btn-icon" type="danger" to="#" shape="circle">
              <FeatherIcon icon="trash-2" size={16} />
            </Button>
          </div>
        );
      },
    },
  ];
};

export const siteTableColumns = showEditModal => {
  return [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    // {
    //   title: '',
    //   dataIndex: 'qrcode',
    //   key: 'qrcode',
    //   render: (text, record) => {
    //     return <QRCode size={20} value={record.qrcode} />;
    //   },
    // },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (text, record) => {
        return (
          <div className="table-actions">
            <Button className="btn-icon" onClick={() => showEditModal(record)} type="info" to="#" shape="circle">
              {/* <FeatherIcon icon="edit" size={16} /> */}
              <ArrowDownOutlined />
              <QrcodeOutlined />
            </Button>
            <Button className="btn-icon" onClick={() => showEditModal(record)} type="info" to="#" shape="circle">
              <FeatherIcon icon="edit" size={16} />
            </Button>
            <Button className="btn-icon" type="danger" to="#" shape="circle">
              <FeatherIcon icon="trash-2" size={16} />
            </Button>
          </div>
        );
      },
    },
  ];
};

export const siteTableColumnsPopUp = showEditModal => {
  return [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    // {
    //   title: '',
    //   dataIndex: 'qrcode',
    //   key: 'qrcode',
    //   render: (text, record) => {
    //     return <QRCode size={20} value={record.qrcode} />;
    //   },
    // },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (text, record) => {
        return (
          <div className="table-actions">
            <QRCode size={16} value={record.qrcode} />{' '}
            <Button className="btn-icon" onClick={() => showEditModal(record)} type="info" to="#" shape="circle">
              <FeatherIcon icon="edit" size={16} />
            </Button>
          </div>
        );
      },
    },
  ];
};

export const dataSource = [
  {
    key: '1',
    name: 'Mike',
  },
  {
    key: '2',
    name: 'John',
  },
];
export const sitesData = [
  {
    key: '1',
    name: 'Mike',
    qrcode: 'https://ant.design/components/table/',
  },
  {
    key: '2',
    name: 'John',
    qrcode: 'https://www.npmjs.com/package/qrcode.react',
  },
];

export const columnsLicence = () => {
  return [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
    },
  ];
};

export const userTableColumns = showEditModal => {
  return [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Access',
      dataIndex: 'access',
      key: 'access',
      // render: (text, record) => {
      //   function handleChange(value) {
      //     console.log(`selected ${value}`);
      //   }
      //   return (
      //     <div>
      //       <Select defaultValue={text} style={{ width: 120 }} onChange={handleChange}>
      //         <Option value="admin">Admin</Option>
      //         <Option value="user">User</Option>
      //         <Option value="grower">Grower</Option>
      //       </Select>
      //     </div>
      //   );
      // },
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (text, record) => {
        return (
          <div className="table-actions">
            <Button size="small" type="primary" outlined>
              Send access
            </Button>
            <Button className="btn-icon" onClick={() => showEditModal(record)} type="info" to="#" shape="circle">
              <FeatherIcon icon="edit" size={16} />
            </Button>
            <Button className="btn-icon" type="danger" to="#" shape="circle">
              <FeatherIcon icon="trash-2" size={16} />
            </Button>
          </div>
        );
      },
    },
  ];
};

export const userData = [
  {
    key: '1',
    name: 'User A',
    email: 'usera@gmail.com',
    access: 'Admin',
  },
  {
    key: '2',
    name: 'User B',
    email: 'userb@gmail.com',
    access: 'User',
  },
  {
    key: '3',
    name: 'User C',
    email: 'userc@gmail.com',
    access: 'Grower',
  },
];

export const datasitePop = [
  {
    key: '0',
    name: `Room 1`,
    qrcode: 'https://ant.design/components/table/',
  },
  {
    key: '1',
    name: `Room 2`,
    qrcode: 'https://ant.design/components/table/',
  },
  {
    key: '2',
    name: `Room 3`,
    qrcode: 'https://ant.design/components/table/',
  },
];

export const openNotificationSuccess = (type, description) => {
  notification[type]({
    message: 'Successful!',
    description,
  });
};
export const openNotificationError = (type, description) => {
  notification[type]({
    message: 'Failed!',
    description,
  });
};
// room content
export const columnsRoomsContent = () => {
  return [
    {
      title: 'amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    // {
    //   title: 'Email',
    //   dataIndex: 'email',
    //   key: 'email',
    // },
    // {
    //   title: 'Access',
    //   dataIndex: 'access',
    //   key: 'access',
    //   // render: (text, record) => {
    //   //   function handleChange(value) {
    //   //     console.log(`selected ${value}`);
    //   //   }
    //   //   return (
    //   //     <div>
    //   //       <Select defaultValue={text} style={{ width: 120 }} onChange={handleChange}>
    //   //         <Option value="admin">Admin</Option>
    //   //         <Option value="user">User</Option>
    //   //         <Option value="grower">Grower</Option>
    //   //       </Select>
    //   //     </div>
    //   //   );
    //   // },
    // },
    // {
    //   title: 'Action',
    //   dataIndex: '',
    //   key: 'x',
    //   render: (text, record) => {
    //     return (
    //       <div className="table-actions">
    //         <Button size="small" type="primary" outlined>
    //           Send access
    //         </Button>
    //         <Button className="btn-icon" onClick={() => showEditModal(record)} type="info" to="#" shape="circle">
    //           <FeatherIcon icon="edit" size={16} />
    //         </Button>
    //         <Button className="btn-icon" type="danger" to="#" shape="circle">
    //           <FeatherIcon icon="trash-2" size={16} />
    //         </Button>
    //       </div>
    //     );
    //   },
    // },
  ];
};

export const downloadCSV = (data, fileName = 'sample.csv') => {
  const csvData = Array.isArray(data)
    ? data.reduce((result, row) => {
        let lineStr = row;
        if (Array.isArray(row)) {
          lineStr = row.join(',');
        }
        return result + lineStr + '\r\n';
      }, '')
    : data;
  const blob = new Blob([csvData], { type: 'text/csv' });
  FileSaver.saveAs(blob, fileName);
};

export function getCurrentRoleString() {
  const loginService = new LoginService();
  const { payload } = loginService.getDecodedToken();
  switch (payload.role_id) {
    case 1:
      return SUPER_ADMIN_ROLE;
    case 2:
      return 'admin';
    default:
      return 'user';
  }
}
