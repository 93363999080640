const initialState = {
  companyId: 0,
};

const accessReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'COMPANY_ID':
      return {
        ...state,
        companyId: action.payload,
      };

    default:
      return state;
  }
};
export default accessReducer;
